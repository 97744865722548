import CollectionProducts from "containers/Pages/Table/CollectionProducts";
import { PropTypes } from "prop-types";
import React from "react";
import { Route, Switch } from "react-router-dom";
import ProtectedRoute from "utils/ProtectedRoute";
import { paths } from "utils/url";
import Dashboard from "../Templates/Dashboard";
import {
  AddAdvertisement,
  AddBranch,
  CreateStores,
  Advertisements,
  AskForEvent,
  BookingCalls,
  Brands,
  Categories,
  ChatPage,
  ChatServicesWidget,
  ChatSettings,
  Collections,
  CreateBrand,
  CreateCategory,
  CreateCollection,
  CreateProduct,
  CreateProductFeature,
  CreatePromotion,
  CreateShop,
  CreateTag,
  CreateTagsTree,
  CreateTheme,
  CreateUser,
  CustomerDetails,
  Customers,
  CustomersReviews,
  // Parent,
  DashboardPage,
  EditBranches,
  ManageOrganization,
  EditStores,
  Employees,
  EventDetails,
  FindReturnRequest,
  GenerateSiteMap,
  HotTagsSearch,
  InfluencerDetails,
  InfluencerEventDetails,
  AmbassadorEventRequestDetails,
  InfluencerRequestsDetails,
  AmbassadorsEventsRequests,
  AmbassadorsRequests,
  ListPromotions,
  ManageCalls,
  ManageQueue,
  ManageEvents,
  ManageAmbassadors,
  AmbassadorProfile,
  ManageOrders,
  ManageOwnerShopRequest,
  ManageOwnersShopsRequests,
  MetaOrder,
  // Table,
  // Maintenance,
  // ComingSoon,
  // Error,
  NotFound,
  // BlankPage,
  Organization,
  // BlankPage,
  OrganizationClasses,
  OrganizationDomains,
  OrganizationImage,
  OrganizationInfo,
  OrganizationThemes,
  ProductFeatures,
  ProductVariants,
  Products,
  ProductsFeatures,
  ProductsImages,
  ReceiptDetails,
  Receipts,
  RelatedCollections,
  RelatedProducts,
  ReturnRequests,
  Reviews,
  ReviewsRequests,
  //
  ScanQr,
  ScanQrDetails,
  SharedItems,
  Shop360Bulk,
  Shop360Editor,
  ShopSections,
  Shops,
  SingleOrder,
  SingleReturnRequest,
  StoreTagsSearch,
  SubAreas,
  SubscribedUsers,
  TagProducts,
  Tags,
  TagsTree,
  Themes,
  // Products360,
  ThemesClasses,
  UpdateAdvertisement,
  UpdateBranch,
  UpdateBrand,
  UpdateCategory,
  UpdateCollection,
  UpdateOrganization,
  UpdateProduct,
  UpdateProductFeature,
  UpdatePromotion,
  UpdateShop,
  UpdateStores,
  UpdateTag,
  UpdateTheme,
  UpdateUser,
  UpdateVariant,
  UploadOrganizationImages,
  UserProfile,
  VideoCallsReviews,
  VideoChat,
  Availability,
  PointsConfiguration,
  CreatePointsConfiguration,
  UpdatePointsConfiguration,
  ControlPoints,
  CreateControlPoint,
  UpdateControlPoint,
  ReviewRequestDetails,
  UpdateAskForEvent,
  // Shipping service
  AddShippingService,
  EditShippingServices,
  UpdateShippingService,
  MyProfile,
  ImportProductsV2,
  ImportProductsImages,
  ChatWidget,
  // Metaverse
  CreateNewEvent,
  // compensation
  CreateCompensation,
  ManageCompensation,
  // 3D Product
  Create3DProduct,
  Manage3DProducts,
  Product3DDetails,
  Edit3DProduct,
  UserCheckout,
  BusinessInformation,
  CreateService,
  ManageService,
  UpdateService,
  CreatePackage,
  ManagePackage,
  UpdatePackage,
} from "../pageListAsync";

class Application extends React.Component {
  render() {
    const { changeMode, history, user } = this.props;
    return (
      <Dashboard history={history} changeMode={changeMode} user={user}>
        <Switch>
          {/* <ProtectedRoute exact path="/" component={BlankPage} /> */}
          <ProtectedRoute
            exact
            path={paths.dashboardPage}
            component={DashboardPage}
          />
          {/* Nasnav Admin only */}
          <ProtectedRoute
            exact
            path={paths.organization}
            component={Organization}
          />
          <ProtectedRoute exact path={paths.addBranch} component={AddBranch} />
          <ProtectedRoute
            exact
            path={paths.allBranches}
            component={EditBranches}
          />
          <ProtectedRoute
            exact
            path={paths.updateBranch}
            component={UpdateBranch}
          />
          <ProtectedRoute
            exact
            path={paths.customersReviews}
            component={CustomersReviews}
          />
          <ProtectedRoute
            exact
            path={paths.manageOrganizations}
            component={ManageOrganization}
          />
          <ProtectedRoute
            exact
            path={paths.UploadOrganizationImages}
            component={UploadOrganizationImages}
          />
          <ProtectedRoute
            exact
            path={paths.updateOrganization}
            component={UpdateOrganization}
          />
          <ProtectedRoute
            exact
            path={paths.manageDomains}
            component={OrganizationDomains}
          />
          <ProtectedRoute
            exact
            path={paths.createCategory}
            component={CreateCategory}
          />
          <ProtectedRoute
            exact
            path={paths.updateCategory}
            component={UpdateCategory}
          />
          {/* Org. Admin and Managers only */}
          <ProtectedRoute
            exact
            path={paths.createBrand}
            component={CreateBrand}
          />
          <ProtectedRoute
            exact
            path={paths.createShop}
            component={CreateShop}
          />
          <ProtectedRoute
            exact
            path={paths.shopSections}
            component={ShopSections}
          />
          <ProtectedRoute
            exact
            path={paths.shop360Bulk}
            component={Shop360Bulk}
          />
          <ProtectedRoute
            exact
            path={paths.shop360Editor}
            component={Shop360Editor}
          />
          <ProtectedRoute
            exact
            path={paths.updateShop}
            component={UpdateShop}
          />
          <ProtectedRoute exact path={paths.shops} component={Shops} />
          <ProtectedRoute exact path={paths.subAreas} component={SubAreas} />
          <ProtectedRoute
            exact
            path={paths.organizationImage}
            component={OrganizationImage}
          />
          <ProtectedRoute
            exact
            path={paths.organizationInfo}
            component={OrganizationInfo}
          />
          <ProtectedRoute
            exact
            path={paths.organizationSiteMap}
            component={GenerateSiteMap}
          />
          <ProtectedRoute
            exact
            path={paths.createUser}
            component={CreateUser}
          />
          <ProtectedRoute
            exact
            path={paths.updateUser}
            component={UpdateUser}
          />
          <ProtectedRoute exact path={paths.employees} component={Employees} />
          <ProtectedRoute
            exact
            path={paths.subscribedUsers}
            component={SubscribedUsers}
          />
          <ProtectedRoute
            exact
            path={paths.manageOrders}
            component={ManageOrders}
          />
          <ProtectedRoute
            exact
            path={paths.singleOrder}
            component={SingleOrder}
          />
          <ProtectedRoute
            exact
            path={paths.singleMetaOrder}
            component={MetaOrder}
          />
          <ProtectedRoute
            exact
            path={paths.findReturnRequest}
            component={FindReturnRequest}
          />
          <ProtectedRoute
            exact
            path={paths.returnRequests}
            component={ReturnRequests}
          />
          <ProtectedRoute
            exact
            path={paths.singleReturnRequest}
            component={SingleReturnRequest}
          />
          <ProtectedRoute
            exact
            path={paths.createAttribute}
            component={CreateProductFeature}
          />
          <ProtectedRoute
            exact
            path={paths.updateAttribute}
            component={UpdateProductFeature}
          />
          <ProtectedRoute
            exact
            path={paths.manageAttributes}
            component={ProductsFeatures}
          />
          <ProtectedRoute exact path={paths.createTag} component={CreateTag} />
          <ProtectedRoute exact path={paths.updateTag} component={UpdateTag} />
          <ProtectedRoute
            exact
            path={paths.tagProducts}
            component={TagProducts}
          />
          <ProtectedRoute exact path={paths.tags} component={Tags} />
          <ProtectedRoute
            exact
            path={paths.createProduct}
            component={CreateProduct}
          />
          <ProtectedRoute
            exact
            path={paths.updateProduct}
            component={UpdateProduct}
          />
          <ProtectedRoute
            exact
            path={paths.relatedProducts}
            component={RelatedProducts}
          />
          <ProtectedRoute exact path={paths.products} component={Products} />
          <ProtectedRoute
            exact
            path={paths.createCollection}
            component={CreateCollection}
          />
          <ProtectedRoute
            exact
            path={paths.updateCollection}
            component={UpdateCollection}
          />
          <ProtectedRoute
            exact
            path={paths.collectionProducts}
            component={CollectionProducts}
          />
          <ProtectedRoute
            exact
            path={paths.relatedCollections}
            component={RelatedCollections}
          />
          <ProtectedRoute
            exact
            path={paths.collections}
            component={Collections}
          />
          <ProtectedRoute
            exact
            path={paths.updateBrand}
            component={UpdateBrand}
          />
          <ProtectedRoute exact path={paths.brands} component={Brands} />
          <ProtectedRoute exact path={paths.tagsTree} component={TagsTree} />
          <ProtectedRoute
            exact
            path={paths.createTagsTree}
            component={CreateTagsTree}
          />
          <ProtectedRoute
            exact
            path={paths.productsImages}
            component={ProductsImages}
          />
          {/* <ProtectedRoute path="/products-360" component={Products360} /> */}
          <ProtectedRoute
            exact
            path={paths.themesClasses}
            component={ThemesClasses}
          />
          <ProtectedRoute
            exact
            path={paths.createTheme}
            component={CreateTheme}
          />
          <ProtectedRoute exact path={paths.themes} component={Themes} />
          <ProtectedRoute
            exact
            path={paths.UpdateTheme}
            component={UpdateTheme}
          />
          <ProtectedRoute
            exact
            path={paths.organizationsClasses}
            component={OrganizationClasses}
          />
          <ProtectedRoute
            exact
            path={paths.organizationThemes}
            component={OrganizationThemes}
          />
          <ProtectedRoute
            exact
            path={paths.createPromotion}
            component={CreatePromotion}
          />
          <ProtectedRoute
            exact
            path={paths.updatePromotion}
            component={UpdatePromotion}
          />
          <ProtectedRoute
            exact
            path={paths.listPromotions}
            component={ListPromotions}
          />
          <ProtectedRoute exact path={paths.reviews} component={Reviews} />
          <ProtectedRoute exact path={paths.customers} component={Customers} />
          <ProtectedRoute
            exact
            path={paths.categories}
            component={Categories}
          />
          <ProtectedRoute exact path={paths.videoChat} component={VideoChat} />
          <ProtectedRoute
            exact
            path={paths.productVariants}
            component={ProductVariants}
          />
          <ProtectedRoute
            exact
            path={paths.updateVariant}
            component={UpdateVariant}
          />
          {/* New Routes */}
          <ProtectedRoute
            exact
            path={paths.manageOwnersShopsRequests}
            component={ManageOwnersShopsRequests}
          />
          <ProtectedRoute exact path={paths.scanQr} component={ScanQr} />
          <ProtectedRoute
            exact
            path={paths.scanQrDetails}
            component={ScanQrDetails}
          />
          <ProtectedRoute
            exact
            path={paths.hotTagsSearch}
            component={HotTagsSearch}
          />
          <ProtectedRoute
            exact
            path={paths.storeTagsSearch}
            component={StoreTagsSearch}
          />
          <ProtectedRoute
            exact
            path={paths.manageAmbassadors}
            component={ManageAmbassadors}
          />
          <ProtectedRoute
            exact
            path={paths.ambassadorProfile}
            component={AmbassadorProfile}
          />
          <ProtectedRoute
            exact
            path={paths.influencerDetails}
            component={InfluencerDetails}
          />
          <ProtectedRoute
            exact
            path={paths.influencerEventDetails}
            component={InfluencerEventDetails}
          />
          <ProtectedRoute
            exact
            path={paths.ambassadorsRequests}
            component={AmbassadorsRequests}
          />
          <ProtectedRoute
            exact
            path={paths.influencerRequestsDetails}
            component={InfluencerRequestsDetails}
          />
          <ProtectedRoute
            exact
            path={paths.customerDetails}
            component={CustomerDetails}
          />
          <ProtectedRoute exact path={paths.chat} component={ChatPage} />
          <ProtectedRoute path={paths.chatInfo} component={ChatPage} />
          <ProtectedRoute
            exact
            path={paths.chatServicesWidget}
            component={ChatServicesWidget}
          />
          <ProtectedRoute
            exact
            path={paths.chatSettings}
            component={ChatSettings}
          />
          <ProtectedRoute exact path={paths.events} component={ManageEvents} />
          <ProtectedRoute
            exact
            path={paths.updateAskForEvent}
            component={UpdateAskForEvent}
          />
          <ProtectedRoute
            exact
            path={paths.eventDetails}
            component={EventDetails}
          />
          <ProtectedRoute
            exact
            path={paths.askForEvent}
            component={AskForEvent}
          />
          <ProtectedRoute
            exact
            path={paths.ambassadorsEventsRequests}
            component={AmbassadorsEventsRequests}
          />
          <ProtectedRoute
            exact
            path={paths.ambassadorEventRequestDetails}
            component={AmbassadorEventRequestDetails}
          />
          <ProtectedRoute
            exact
            path={paths.createService}
            component={CreateService}
          />
          <ProtectedRoute
            exact
            path={paths.manageService}
            component={ManageService}
          />
          <ProtectedRoute
            exact
            path={paths.updateService}
            component={UpdateService}
          />
          <ProtectedRoute
            exact
            path={paths.createPackage}
            component={CreatePackage}
          />
          <ProtectedRoute
            exact
            path={paths.managePackage}
            component={ManagePackage}
          />
          <ProtectedRoute
            exact
            path={paths.updatePackage}
            component={UpdatePackage}
          />
          <ProtectedRoute
            exact
            path={paths.manageCalls}
            component={ManageCalls}
          />
          <ProtectedRoute
            exact
            path={paths.manageQueue}
            component={ManageQueue}
          />
          <ProtectedRoute
            exact
            path={paths.bookingCalls}
            component={BookingCalls}
          />
          {/* <ProtectedRoute
            exact
            path={paths.videoCallsReviews}
            component={VideoCallsReviews}
          /> */}
          <ProtectedRoute
            exact
            path={paths.availability}
            component={Availability}
          />
          <ProtectedRoute
            exact
            path={paths.sharedItems}
            component={SharedItems}
          />
          <ProtectedRoute
            exact
            path={paths.reviewsRequests}
            component={ReviewsRequests}
          />
          <ProtectedRoute
            exact
            path={paths.reviewRequestDetails}
            component={ReviewRequestDetails}
          />
          <ProtectedRoute
            exact
            path={paths.manageReceipts}
            component={Receipts}
          />
          <ProtectedRoute
            exact
            path={paths.receiptDetails}
            component={ReceiptDetails}
          />
          <ProtectedRoute
            exact
            path={paths.userProfile}
            component={UserProfile}
          />
          <ProtectedRoute
            exact
            path={paths.addAdvertisement}
            component={AddAdvertisement}
          />
          <ProtectedRoute
            exact
            path={paths.updateAdvertisement}
            component={UpdateAdvertisement}
          />
          <ProtectedRoute
            exact
            path={paths.advertisements}
            component={Advertisements}
          />
          <ProtectedRoute
            exact
            path={paths.manageOwnerShopRequest}
            component={ManageOwnerShopRequest}
          />
          <ProtectedRoute exact path={paths.createStores} component={CreateStores} />
          <ProtectedRoute
            exact
            path={paths.allStores}
            component={EditStores}
          />
          <ProtectedRoute
            exact
            path={paths.updateStores}
            component={UpdateStores}
          />
          {/* New Routes */}
          {/* <ProtectedRoute path="/table" component={Table} />
          <ProtectedRoute path="/page-list" component={Parent} /> */}
          {/* <Route path="/pages/maintenance" component={Maintenance} />
          <Route path="/pages/coming-soon" component={ComingSoon} />
          <Route path="/pages/not-found" component={NotFound} />
          <Route path="/pages/error" component={Error} /> */}
          <ProtectedRoute exact path={paths.pointsConfiguration} component={PointsConfiguration} />
          <ProtectedRoute exact path={paths.createPointsConfiguration} component={CreatePointsConfiguration} />
          <ProtectedRoute exact path={paths.updatePointsConfiguration} component={UpdatePointsConfiguration} />
          <ProtectedRoute exact path={paths.controlPoints} component={ControlPoints} />
          <ProtectedRoute exact path={paths.createControlPoints} component={CreateControlPoint} />
          <ProtectedRoute exact path={paths.updateControlPoint} component={UpdateControlPoint} />
          <ProtectedRoute exact path={paths.addShippingService} component={AddShippingService} />
          <ProtectedRoute exact path={paths.allShippingServices} component={EditShippingServices} />
          <ProtectedRoute exact path={paths.updateShippingService} component={UpdateShippingService} />
          <ProtectedRoute exact path={paths.myProfile} component={MyProfile} />
          <ProtectedRoute exact path={paths.importProductsV2} component={ImportProductsV2} />
          <ProtectedRoute exact path={paths.importProductsImages} component={ImportProductsImages} />
          <ProtectedRoute exact path={paths.chatWidget} component={ChatWidget} />
          <ProtectedRoute exact path={paths.createNewEvent} component={CreateNewEvent} />
          <ProtectedRoute exact path={paths.createCompensation} component={CreateCompensation} />
          <ProtectedRoute exact path={paths.manageCompensation} component={ManageCompensation} />
          <ProtectedRoute exact path={paths.create3DProduct} component={Create3DProduct} />
          <ProtectedRoute exact path={paths.manage3DProducts} component={Manage3DProducts} />
          <ProtectedRoute exact path={paths.product3DDetails} component={Product3DDetails} />
          <ProtectedRoute exact path={paths.edit3DProduct} component={Edit3DProduct} />
          <ProtectedRoute exact path={paths.userCheckout} component={UserCheckout} />
          <ProtectedRoute exact path={paths.businessInformation} component={BusinessInformation} />
          <Route component={NotFound} />
        </Switch>
      </Dashboard>
    );
  }
}

Application.propTypes = {
  changeMode: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

export default Application;
